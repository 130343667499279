import React from 'react';
import './App.css';

function Footer() {
  return (
        <>
          <footer>
            <div class="footer-content">
              <p>&copy; 2024 Sasuvika Consultancy Inc. All rights reserved. </p>
              <div class="social-icons">
              </div>
            </div>
          </footer>
        </>
  );
}

export default Footer;
