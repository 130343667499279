import React from 'react';
import './App.css';
import Footer from "./Footer.js"

function Home() {
  return (
  <html lang="en">
      <head>
        <meta charset="utf-8" />
        <link rel="icon" href="%PUBLIC_URL%/sasuvika-icon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name='description' content='Discover steps to successful Custom Software Development , Website Development : defining requirements,  planning, designing and development.'/>.

        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />

        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      
        <title>Custom Software Development for small businesses</title>
      
      </head>
      <body>
        <div class="container">
          <div class="home-content">
            <ul>
                
            </ul>
          </div>
          <div>
              <h1 class='a-h-text'>Custom Software Development , Website Development for small businesses</h1>
              
              <blockquote>
                    "Custom software development for small businesses involves creating tailored software solutions to address your company’s specific needs and challenges. This approach can yield significant benefits, such as reducing costs, adding bespoke features, automating manual processes, enhancing data security, and providing robust technical support. The critical steps in this process include defining business objectives and requirements, planning, collaborating with business partner feedback, designing and prototyping, development and testing, deployment and training, and ongoing support and maintenance. Partner with us to transform your business operations with custom software solutions designed specifically for your business."
              </blockquote>
              
          </div>
          
          <Footer/>
        </div>
      </body>
      </html>
  );
}

export default Home;
