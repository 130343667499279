import React from 'react';
import './App.css';

function Support() {
  return (
    <div className='support'>
      <h1>Support</h1>
    </div>
  );
}

export default Support;
