import React from 'react';
import Footer from "./Footer.js"
import aboutImage from './image-sasi.png';

function AboutUs() {
  return (
    <>
    <div className="about-container">
      <div className="image-container">
        <img src={aboutImage} alt="About Us" />
      </div>
      <div className="description-container">
        <h4>Owner </h4>
        <p> Sasikumar N | M.S in Software Engineering</p>
        <p> He has been working in the Software industry for 22 years</p>
        <p> Expertise in Microservices, Cloud Platforms, Data Lake for AI/ML and Frontend Technologies</p>
      </div>
    </div>

    <div className='aboutus'>
      <h1 className='a-heading'>About Us</h1>
      <image> </image>
      <li className='a-text'>Our company, based in the Greater Toronto Area (GTA), boasts a diverse talent pool proficient in Java, Spring Cloud, Spring Data, and Spring Restful.</li>
      <li className='a-text'>With expertise in building robust microservices architectures, middleware components, we leverage leading cloud platforms such as Microsoft Azure, Google Cloud Platform, and Amazon Web Services (AWS).</li>
      <li className='a-text'>Additionally, our team specializes in AI and machine learning, utilizing powerful tools like BigQuery, RedShift, Data Bricks, Snowflake, and the ELK stack.</li>
      <li className='a-text'>We excel in setting up cloud environments tailored to your software needs, ensuring scalability, reliability, and security.</li>
      <li className='a-text'>From initial setup to ongoing management, we provide comprehensive cloud solutions to streamline your operations.</li>
      <li className='a-text'>Moreover, our proficiency extends to seamless migration services, facilitating the transition from on-premises infrastructure to cloud environments.</li>
      <li className='a-text'>Leveraging our deep understanding of cloud technologies, we optimize performance and cost-effectiveness to maximize your ROI.</li>
      <li className='a-text'>Our dedication to staying abreast of industry trends ensures that your cloud infrastructure remains cutting-edge and future-proof.</li>
      <li className='a-text'>Whether you're a startup or an established enterprise, we tailor our services to meet your specific requirements and goals.</li>
      <li className='a-text'>Partner with us to unleash the full potential of cloud computing and propel your business towards success in the digital era.</li>
    </div>
    <Footer/>
    </>
  );
}

export default AboutUs;
