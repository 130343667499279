import React from 'react';
import EmailForm from "../components/EmailForm.jsx";
import "./contactus.css";
import Footer from "./Footer.js"

export default function ContactUs() {
  return (
    <>
      <div className='c-screen'>
          <EmailForm />
      </div>
      <Footer/>
    </>
  );
}
