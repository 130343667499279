import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Home',
    path: '/home',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Services',
    path: '/services',
    icon: <IoIcons.IoIosCloudy />,
    cName: 'nav-text'
  },
  {
    title: 'Contact Us',
    path: '/contactus',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  },
  {
    title: 'About Us',
    path: '/aboutus',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  },
];
