import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import Services from './pages/Services';
import Support from './pages/Support';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  createRoutesFromElements,
} from "react-router-dom";
import Home from './pages/Home';
import Products from './pages/Products';
import Reports from './pages/Reports';
import Navbar from "./components/Navbar";


const AppLayout = () => (
  <div >
    <Navbar />
    <Outlet />
  </div>
);
const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "products",
        element: <Products />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "support",
        element: <Support />,
      },
      {
        path: "contactus",
        element: <ContactUs />,
      },
      {
        path: "aboutus",
        element: <AboutUs />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  
  <RouterProvider router={router} />

);